














import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    name: "QuestObjective",
})
export default class QuestObjective extends Vue {

    @Prop()
    protected id!: number;

    @Prop()
    protected task!: string;
}
